import * as React from 'react'
import { graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { MDXRenderer } from "gatsby-plugin-mdx"


import styled from 'styled-components';

import Grid from '../../components/Helpers/Grid';

import Layout from '../../components/Layout';

import MoreInfo from '../../components/Detail/MoreInfo';
import Buttons from '../../components/Detail/Buttons';
import DetailImage from '../../components/Detail/DetailImage';

const EventDetailWraper = styled.div`
    text-align: center;
    margin: 0 auto;
    padding: 30px;
    margin-bottom: 20px;
    height: auto;
    width: 70vw; 
    h1 { 
      font-size: 65px;
      padding-bottom: 40px;
    }
    h2 { 
      padding-bottom: 10px;
      padding-top: 20px;
    }    


    @media screen and (max-width: 960px) {
      width: 90vw;      
    }
 
`
const ColumnWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
`

const MdxBodyWraper = styled.div`
    text-align: left;
`

const DetailsWraper = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;  
`

const ImageWraper = styled.div`

  img {
      margin-bottom: 16px;
      height: 400px; 
      width: 400px;
      object-fit: cover; 
      border-radius: 50%;
      background-color: #f1f1f1;
  }  

  text-align: center;  
  margin-bottom: 20px;

  height: auto;
  width: auto;  

  @media screen and (max-width: 960px) {
    width: auto;  
    img {
      margin-bottom: 16px;
      height: 200px; 
      width: 200px;
      object-fit: cover; 
      border-radius: 50%;
      background-color: #f1f1f1;
    }      
  }



`

const LinkWraper = styled.a`
    padding-left:20px;
`

const EventDetail = ({ data, children }) => {

  const image = getImage(data.mdx.frontmatter.image);
  //const detail = data.markdownRemark

  return (
    <Layout section="events" pageTitle="Events">
      <EventDetailWraper>
        <h1>{ data.mdx.frontmatter.title }</h1>      
        <Grid columns={2} style={{ textAlign: "center" }}>                 
          <ColumnWrapper>
            <DetailImage image={image} />
          </ColumnWrapper>
          <ColumnWrapper>                        
            <MdxBodyWraper>
                <MDXRenderer>
                  { data.mdx.body }
                </MDXRenderer>
              <MoreInfo data={data} />            
              <Buttons data={data} />
            </MdxBodyWraper>            
          </ColumnWrapper>
        </Grid>        
      </EventDetailWraper>
    </Layout>
  )

}


export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        showdate
        image_alt
        image {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
          }
        }
        whatsap
        notion
        instagram
        address
        map
        link
        phone
      }
      slug
      body
    }
  }
`

//export const Head = () => <Seo title="Super Cool Blog Posts" />

export default EventDetail